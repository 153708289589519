import React from "react";
import "./blogs.css";
const Blogs = () => {
  const [blogs, setBlogs] = React.useState([]);
  const getStreamData = async () => {
    try {
      const response = await fetch(
        "https://ccc-api-wc4f.onrender.com/api/medium-posts/cloudcomputingclub"
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const reader = response.body.getReader();
      let decoder = new TextDecoder();
      let result = await reader.read();
      let data = "";

      while (!result.done) {
        data += decoder.decode(result.value, { stream: true });
        result = await reader.read();
      }

      // Process remaining data if any
      data += decoder.decode(result.value, { stream: false });
      const blogdata = await JSON.parse(data);
      return blogdata;
    } catch (error) {
      console.error("Error fetching data:", error);
      return null;
    }
  };

  const getBlogs = async () => {
    const data = await getStreamData();
    console.log(data);
    setBlogs(data.dataMedium);
  };

  React.useEffect(() => {
    getBlogs();
  }, []);
  return (
    <>
      <section className="blogs container">
        <h1 className="text-center title ">
          <span>Blogs</span>
        </h1>
        <div className="blog-grid">
          {blogs.map((blog) => {
            return (
              <div className="blog">
                <div className="blog__header">
                  <img
                    src={blog.image}
                    alt="blog__image"
                    className="blog__image"
                    width={600}
                  />
                </div>
                <div className="blog__body">
                  <h4>{blog.title} </h4>
                  <p>{blog.description.substring(0, 100)}...</p>
                  <a href={blog.link} target="*" className="blog-btn">
                    Read on medium
                  </a>
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
};
//https://v1.nocodeapi.com/meproxy/medium/rcUHnQppHgljezUY

export default Blogs;
