import React from "react";
import "./Gallery.css";
import logo from "../../Images/club_logo.png";

const GalleryPage = () => {
  const gallery1 = [
    {
      id: 1,
      name: "Hackathon",
      img: "https://i.ibb.co/pynGFT8/IMG-2996.jpg",
    },
    {
      id: 1,
      name: "Hackathon",
      img: "https://i.ibb.co/3vkwsdt/20230420-180819.jpg",
    },

    {
      id: 1,
      name: "Hackathon",
      img: "https://i.postimg.cc/yxqsYL19/IMG-20230420-WA0022-min.jpg",
    },
    {
      id: 1,
      name: "Hackathon",
      img: "https://i.ibb.co/WB3mjhb/20230419-172639.jpg",
    },
  ];
  const gallery2 = [
    {
      id: 1,
      name: "Hackathon",
      img: "https://i.ibb.co/pynGFT8/IMG-2996.jpg",
    },
    {
      id: 1,
      name: "Hackathon",
      img: "https://i.ibb.co/3vkwsdt/20230420-180819.jpg",
    },

    {
      id: 1,
      name: "Hackathon",
      img: "https://i.postimg.cc/yxqsYL19/IMG-20230420-WA0022-min.jpg",
    },
    {
      id: 1,
      name: "Hackathon",
      img: "https://i.ibb.co/WB3mjhb/20230419-172639.jpg",
    },
  ];
  return (
    <>
      {/* gallery1 */}
      <div className="main" data-aos="fade-up">
        <div className="marquee">
          <div className="marquee__group">
            {gallery1.map((achievement) => {
              return <img src={achievement.img} alt={achievement.name} />;
            })}
          </div>
          <div aria-hidden="true" className="marquee__group">
            {gallery1.map((achievement) => {
              return <img src={achievement.img} alt={achievement.name} />;
            })}
          </div>
        </div>
        <div className="marquee marquee--borders" style={{ duration: "100s" }}>
          <div className="marquee__group">
            <p>Cloud Computing Club</p>
            <p aria-hidden="true">
              <img src={logo} width="80" height="100" />
            </p>
            <p aria-hidden="true">Cloud Computing Club</p>
            <p aria-hidden="true">
              <img src={logo} width="80" height="100" />
            </p>
          </div>
          <div aria-hidden="true" className="marquee__group">
            <p>Cloud Computing Club</p>
            <p>
              <img src={logo} width="80" height="100" />
            </p>
            <p>Cloud Computing Club</p>
          </div>
        </div>
        <div className="marquee marquee--reverse">
          <div className="marquee__group">
            {gallery1.map((achievement) => {
              return <img src={achievement.img} alt={achievement.name} />;
            })}
          </div>
          <div aria-hidden="true" className="marquee__group">
            {gallery1.map((achievement) => {
              return <img src={achievement.img} alt={achievement.name} />;
            })}
          </div>
        </div>
      </div>
      <div className="g-mid" data-aos="zoom-in">
        <div className="image-grid" >
          <img
            src="https://i.ibb.co/YjXyGZj/teacher-1.jpg"
            alt="a house on a mountain"
          />
          <img
            src="https://i.ibb.co/0jmhg8y/IMG20230901135053.jpg"
            alt="some pink flowers"
          />
          <img
            src="https://i.ibb.co/LSSRk4h/20220918-165055.jpg"
            alt="big rocks with some trees"
          />
          <img
            src="https://i.ibb.co/ZVGW8WV/IMG-20220918-163823-6.jpg"
            alt="a cool landscape"
          />
        </div>

        <div className="octagon" >
          <div className="gallery">
            <img
              src="https://i.ibb.co/XFzbsfx/IMG-1247.jpg"
              alt="a house on a mountain"
            />
            <img
              src="https://i.ibb.co/K2fqr8G/IMG20230902134353.jpg"
              alt="a house on a mountain"
            />
            <img
              src="https://i.ibb.co/DwL47Yw/IMG-4818.jpg"
              alt="a house on a mountain"
            />
            <img
              src="https://i.ibb.co/PjdLK1N/IMG-4807.jpg"
              alt="a house on a mountain"
            />
            <img
              src="https://i.ibb.co/VjpqDLm/IMG-20230915-170301.jpg"
              alt="a house on a mountain"
            />
            <img
              src="https://i.ibb.co/3mx90D6/IMG-20240413-105949.jpg"
              alt="a house on a mountain"
            />
            <img
              src="https://i.ibb.co/wgF1pNy/lst.jpg"
              alt="a house on a mountain"
            />
          </div>
        </div>
      </div>

      <div data-aos="zoom-in" className="main-img">
        <img src="https://i.ibb.co/jkHGfyW/cccfull.jpg" alt="" />
      </div>
    </>
  );
};

export default GalleryPage;
