import React from 'react'

const Tech = () => {
  return <section className="blogs container">
    <div className="card p-5  shadow-lg text-center"
      style={{ marginTop: '20%', marginBottom: '20%', borderRadius: 20, border: 'none' }}>
      <h1><span>Tech</span>  Updating Soon... <br /> <i style={{marginTop: 30, padding: 0}} className="fas fa-poo"></i></h1>
    </div>
  </section>
}

export default Tech
