import React from 'react'

const AboutUs = () => {
  return <section className="blogs container">
    <div className="card p-5  shadow-lg text-center"
      style={{ marginTop: '20%', marginBottom: '20%', borderRadius: 20, border: 'none' }}>
      <h1> <span>About Us</span></h1>
      <h3><article className="home--text" data-aos="fade-up" data-aos-duration="600" data-aos-easing="ease-out">
      We established on the year of 2016 by aiming to provide opportunities for all students to explore cloud computing technology through fun and hands-on activities. </article></h3>
    </div>
  </section>
}

export default AboutUs;
