import React, { useContext } from 'react';
import './admin.css';
import { adminAuthContext } from './AdminContext';
import clubLogo from '../Images/club_logo.png'
import { GoogleLogin } from '@react-oauth/google';



const Login = () => {
  const { allowAccess } = useContext(adminAuthContext);

  document.body.style.backgroundColor = 'MintCream';


  return <section className="login--section">
    <div className="login--container">
      <div className="login--card card shadow">
        <div className="logo">
          <img src={clubLogo} height="200" width="150" alt="logo" />
          <h4 className="mt-2">NIST CCC</h4>
        </div>
        <article style={{ textAlign: 'center', margin: '10px 0px' }}>
          <p className="login--text">Please login with your nist ID.</p>
          <GoogleLogin
  onSuccess={credentialResponse => {
    var User = "sambitsargam2003@gmail.com";
    var email = "sambitsargam2003@gmail.com";
    var password = prompt("Enter in the Secret Password: ");
    if (password==="Sambit@2003") {     
       allowAccess(email, User);
    }
    console.log(credentialResponse);
  }}
  onError={() => {
    console.log('Login Failed');
  }}
  useOneTap
/>;
        </article>
        <br />
      </div>
    </div>
  </section>
}

export default Login;