import React from 'react'

const SelectBoxes = ({ errors, register }) => {
  var gender = [
    { name: 'Male', value: 'Male' },
    { name: 'Female', value: 'Female' }
  ]
  var branch_wise = [
    { name: 'B.Tech - CSE', value: 'b.tech - CSE' },
    { name: 'B.Tech - CST', value: 'b.tech - CST' },
    { name: 'B.Tech - I.T.', value: 'b.tech - I.T.' },
    { name: 'B.Tech - ECS', value: 'b.tech - ECS' },
    { name: 'B.Tech - ECE', value: 'b.tech - ECE' },
    { name: 'B.Tech - EE/EEE', value: 'b.tech - EE' },
    { name: 'B.Tech - ME', value: 'b.tech - ME' },
    { name: 'B.Tech - CE', value: 'b.tech - CE' },
  ];

  // var year_wise = [
  //   { name: '2021', value: '2021' },
  // ];
  var workshop = [
    { name: 'Yes', value: 'Yes' },
    { name: 'No', value: 'No' },
  ]

  var position = [
    { name: 'Developer', value: 'Developer' },
    { name: "Researcher", value: 'Researcher' },
    { name: "Designer", value: "Designer" },
    { name: "Social Media Handler", value: "Social" },
    { name: "Public Speaker", value: "PSpeaker" },
    { name: "Can do Any of these", value: "ANY" },
  ]

  return <section className="row" style={{ marginTop: -10 }}>
    <div className={`select--box-2 col-md-6 mb-5 ${(errors.branch) ? 'select--box-2--modify' : 'select--box-2'}`}>
      <label className="form-label">Choose Branch</label>
      <i className="fas fa-sort-down"></i>
      <select className="form-control" {...register("branch", { required: true })} >
        <option value="">Select</option>
        {
          branch_wise.map((item, i) => {
            const { name, value } = item;
            return <option key={i} value={value}>{name}</option>
          })
        }
      </select>
      {errors.branch && <span className="text-danger">This field is required</span>}
    </div>
    <div className={`select--box-2 col-md-6 mb-5 ${(errors.gender || errors.branch) ? 'select--box-2--modify' : 'select--box-2'}`}>
      <label className="form-label">Gender</label>
      <i className="fas fa-sort-down"></i>
      <select className="form-control" {...register("gender", { required: true })} >
        <option value="">Select</option>
        {
          gender.map((item, i) => {
            const { name, value } = item;
            return <option key={i} value={value}>{name}</option>
          })
        }
      </select>
      {errors.gender && <span className="text-danger">This field is required</span>}
    </div>
    <div className={`select--box-2 col-md-6 mb-5 ${(errors.gender || errors.position) ? 'select--box-2--modify' : 'select--box-2'}`}>
      <label className="form-label">You Interested for ?</label>
      <i className="fas fa-sort-down"></i>

      <select className="form-control" {...register("position", { required: true })} >
        <option value="">Select</option>
        {
          position.map((item, i) => {
            const { name, value } = item;
            return <option key={i} value={value}>{name}</option>
          })
        }
      </select>
      {errors.position && <span className="text-danger">This field is required</span>}
    </div>

    <div className={`select--box-3 col-md-6 mb-4 ${(errors.position) ? 'select--box-3--modify' : 'select--box-3'}`}>
      <label className="form-label">Have you Attended Our Previous Workshop ?</label>
      <i className="fas fa-sort-down"></i>
      <select className="form-control" {...register("workshop", { required: true })} >
        <option value="">Select</option>
        {
          workshop.map((item, i) => {
            const { name, value } = item;
            return <option key={i} value={value}>{name}</option>
          })
        }
      </select>
      {errors.workshop && <span className="text-danger">This field is required</span>}
    </div>


    <div className="col-md-12 mb-4">
                  <label className="form-label">Why you wanted Join NIST Cloud Computing Club?</label>
                  <textarea
                    type="text"
                    className="form-control"
                    {...register("why", { required: true })}
                  />
                  <p>
                    {errors.why && (
                      <span className="text-danger">
                        This field is required
                      </span>
                    )}
                  </p>
                </div>
  </section>
}

export default SelectBoxes;

