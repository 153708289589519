import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import "./testimonial.css";

export default class Testimonials extends Component {
  render() {
    return (
      <Carousel
        showArrows={true}
        infiniteLoop={true}
        showThumbs={false}
        showStatus={false}
        autoPlay={true}
        interval={4000}
      >

<div>
          <img src="https://user-images.githubusercontent.com/70655824/200862278-cb4db072-1dd7-4547-a628-1a5bb455fc55.jpg" alt="asish sir's pic" />
          <div className="myCarousel">
            <h3>Prof. Ashish Kumar Dass</h3>
            <h4>Advisor</h4>
            <p>
            Cloud computing is an umbrella term for any IT resources that the consumer uses over the Internet. The end-user outsources ready-made resources and accesses them online instead of relying on local infrastructure.
            </p>
          </div>
        </div>
        <div>
          <img src="https://user-images.githubusercontent.com/70655824/208967421-4662b43a-2bcc-456f-9407-a815c9567106.jpg" alt="co-advisor-sir's pic" />
          <div className="myCarousel">
            <h3>Prof. Ashutosh Parida</h3>
            <h4>Co-Advisor</h4>
            <p>
            The cloud revolution has changed the face of how enterprises work, operate, and do business. Today, cloud computing is vital to helping business operations become predictable, reliable, cost-efficient and less dependent on specialized IT resources. 
            </p>
          </div>
        </div>
        <div>
          <img src="https://user-images.githubusercontent.com/70655824/208968411-26fb3c82-3845-43ed-afa4-9b11ddff220e.jpg" alt="lk-sir's pic" />
          <div className="myCarousel">
            <h3>Dr. L.K. Tripathy</h3>
            <h4>Regional Director of Education, Berhampur</h4>
            <p>
           Cloud Computing is on of the hottest topics which can bring a revolution in computer data data storing. Projects done by students are verymuch innovative and there is a lot of prospect in future.
            </p>
          </div>
        </div>
        
        {/* <div>
          <img src="https://nist.edu/faculty/images/principal_faculty.jpg" alt="principal-sir's pic" />
          <div className="myCarousel">
            <h3>Dr Priyadarshi Tripathy</h3>
            <h4>Principal</h4>
            <p>
            --------------------------------------------------------------------------------
            </p>
          </div>
        </div>
       
        <div>
          <img src="https://nist.edu/faculty/FacultyPhotos/201902658.jpg" alt="hod-sir's pic" />
          <div className="myCarousel">
            <h3>Dr.Sudhir Ranjan Pattanaik</h3>
            <h4>HOD,School of Computer Science</h4>
            <p>
          --------------------------------------------------------------------------------
            </p>
          </div>
        </div> */}
      </Carousel>
    );
  }
}