import React from "react";
import Svg1 from "../Component/SVGs/Svg1";
import TechWeDrive from "../Component/TechWeDrive";
import Events from "./Events/Events";
import logo from "../Images/club_logo.png";
import Testimonial from "../Component/Testimonial";
// import CarouselFade from "../Component/Carousel";
import '../Pages/Hiring/hiring.css'
const Home = () => {
  document.body.style.backgroundColor = "white";
  document.title = "NIST CCC | Home";

  const someFun1 = () => {
    document
      .getElementById("event-section")
      .scrollIntoView({ behavior: "smooth" });
  };

  return (
    <section className="home--section">
      <article className="socials">
        <p>
          <a
            href="https://www.linkedin.com/company/nist-cloud-computing-club"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fab fa-linkedin text-primary"></i>
          </a>
        </p>
        <p>
          <a
            href="https://www.github.com/NIST-CCC"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fab fa-github text-primary"></i>
          </a>
        </p>
        <p>
          <a
            href="https://www.instagram.com/cloudcomputingclub_official/"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fab fa-instagram"></i>
          </a>
        </p>
        <p>
          <a
            href="https://www.facebook.com/ccc.nist/"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fab fa-facebook-square"></i>
          </a>
        </p>
        <p>
          <a
            href="https://www.youtube.com/@cloudcomputingclub"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fab fa-youtube"></i>
          </a>
        </p>
        <p>
          <a
            href="https://www.medium.com/@cloudcomputingclub"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fab fa-medium"></i>
          </a>
        </p>
      </article>
      <div className="">
        <article
          className="home--text"
          data-aos="fade-up"
          data-aos-duration="600"
          data-aos-easing="ease-out"
        >
          <h1>NIST </h1>
          <p>
            <b>Cloud Computing</b> Club
          </p>
          <h6>
            An Education Society deals with the Cloud and Services at NIST
            Campus.
          </h6>
        </article>

        <div className="btn--explore">
          <button
            onClick={() => someFun1()}
            className="btn btn-info"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-easing="ease-out"
          >
            Explore more &nbsp;&nbsp;{" "}
            <i className="fas fa-chevron-circle-down"></i>
          </button>
        </div>
        <img
          className="img--home"
          data-aos="zoom-in"
          data-aos-duration="1000"
          data-aos-easing="ease-out"
          width="350"
          height="500"
          src={logo}
          alt=""
        />
      </div>
      <section className="tech--explore--section">
        <article className="svg--1">
          <Svg1 />
        </article>
        <main className="p-3 text-white">
          <h2>Technology we Drive.</h2>
          <TechWeDrive />
        </main>
        <article
          className="svg--2"
          style={{ marginTop: -50 }}
          id="event-section"
        >
          <Svg1 />
        </article>
      </section>

      {/* <CarouselFade /> */}
      <section className="review--section">
        <h2>Testimonial</h2>
        <Testimonial />
      </section>

      {/* <section className="events--section">
        <h2>Events.</h2>
        <Events />
      </section> */}
      <section className="hiring--process" >
      <h1 className="text-center">Selection Process</h1>

      <div
            className="card shadow-lg  recruitment-card animate__animated"
            style={{ borderRadius: 20, width:"80vw", margin:"auto auto" }}
          >
            <article className="card-body text-center mt-4">
              <h1 className="card-title">
                Empowering the Next Wave: Recruitment of Elite Members to the
                Cloud Computing Club - Batch 2023
              </h1>
              <p style={{ fontSize: 18, marginBottom: 40 }}>
                Registration for the recruitment process is now open. Join us to
                innovate and shape the future of cloud computing!
              </p>
              <a href="https://new-hire-ccc.vercel.app/" target="#" className="btn btn-bg btn-lg">Register Now</a>
            </article>
          </div>
      </section>
    </section>
  );
};

export default Home;
