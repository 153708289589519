export const eventDetails = [
  {
    id: 2,
    name: "Serverless in Action",
    describe: `Microservices architecture is a distributed design approach intended to overcome the limitations of traditional monolithic architectures.`,
    date: "26-10-2021",
    type: "event",
    time: "",
    venue: "Galeria",
    Days: 2,
    event_type: "Previous",
    check_type: true,
    logo: "https://www.freecodecamp.org/news/content/images/size/w2000/2022/01/Serverless-computing-768x402-1.png",
    short_desc: "👉  Building Cloud Native Application with AWS",
  },
  {
    id: 4,
    name: "Unlocking the Power of AWS EC2 and S3: A Comprehensive Workshop",
    describe: `Learn website design and AWS hosting with EC2, S3, and Other Service. Create a functional website to showcase your work and new skills. `,
    date: "14th and 15th November, 2023",
    time: "",
    type: "event",
    venue: "Galeria",
    Days: 2,
    event_type: "Previous",
    check_type: true,
    logo: "https://bafybeibbeijvb5g6grgxocbtxr4fi7ps4fydifd6xrcug6mymjco3d3j5y.ipfs.w3s.link/pngkey.com-blue-clouds-png-5715694.png",
    short_desc: "👉  Learn , Implement & Win exciting prizes",
  },

  {
    id: 5,
    name: "Designing and Hosting Your Portfolio Website with  Cloud!",
    describe: `Learn portfolio design and AWS hosting with EC2, S3, and Other Service. Create a functional website to showcase your work and new skills. `,
    date: "19th and 20th April, 2023",
    time: "",
    type: "event",
    venue: "WIll Update",
    Days: 2,
    event_type: "Previous",
    check_type: true,
    logo: "https://images.ctfassets.net/aoyx73g9h2pg/uiCpQ0yAnqZ7eKddDGB3G/da88b5fa6021bf7b41aa33b80eb6a48f/devops.png",
    short_desc: "👉  Learn , Implement & Win exciting prizes",
    form_url: "/events-portfolio",
  },
  {
    id: 6,
    name: "GAME EVENT",
    describe: `Update Soon (Stay Tuned)`,
    date: "30th and 31th March, 2023",
    time: "10.30am onwards",
    type: "event",
    venue: "Galeria ",
    Days: 2,
    event_type: "Previous",
    check_type: true,
    logo: "https://user-images.githubusercontent.com/70655824/226181911-e2b1c2c3-0654-42a5-a809-ea7d7bf2c0e8.jpg",
    short_desc: "👉 Play & Win exciting prizes",
    form_url: "/events-game",
  },

  {
    id: 1,
    name: "Brain Teaser",
    describe: `It is always good to learn more about our brains and to exercise them!`,
    date: "26-10-2021",
    time: "",
    venue: "Galeria",
    type: "event",
    Days: 2,
    event_type: "Previous",
    check_type: true,
    logo: "https://www.pngkit.com/png/full/26-267054_cloud-puzzle-3d-rendering.png",
    short_desc: "👉  Play, Solve & Win exciting prizes",
  },
  {
    id: 3,
    name: "Cloud Computing Idea Festival",
    describe: `Transform your idea with cloud computing - a flexible, cost-effective, and scalable solution that unlocks unlimited potential. Go ahead of the competition by embracing the power of the cloud`,
    date: "30th and 31th March, 2023",
    time: "10.30am onwards",
    type: "event",
    venue: "Galeria ",
    Days: 2,
    event_type: "Previous",
    check_type: true,
    logo: "https://user-images.githubusercontent.com/70655824/226100186-bab08d39-7496-495d-b0f6-ae0f8d869815.jpg",
    short_desc: "👉 Pitch & Win exciting prizes",
  },

  {
    id: 7,
    name: "Cloud Computing Idea Festival",
    describe: "Elevate your minds to the sky with cloud computing",
    date: "21th and 22th March, 2024",
    time: "Update Soon",
    type: "event",
    venue: "Update Soon",
    Days: 2,
    event_type: "Previous",
    check_type: true,
    logo: "https://i.ibb.co/fk7yzLQ/idea-fest.png",
    short_desc: "👉 Pitch & Win exciting prizes",
    form_url: "/events-2",
    wp_grp_url: "https://chat.whatsapp.com/JHQ1BlOhb3J4U0ktdJpJUZ",
  },

  {
    id: 8,
    name: "Poster Competition",
    describe:
      "Unleash your creativity and design a Poster that speaks volumes!",
    date: "21th and 22th March, 2024",
    time: "Update Soon",
    type: "event",
    venue: "Galeria ",
    Days: 2,
    event_type: "Previous",
    check_type: true,
    logo: "https://i.ibb.co/ZLSWTpT/canva-event.png",
    short_desc: "👉 Pitch & Win exciting prizes",
    form_url: "/events-3",
    wp_grp_url: "https://chat.whatsapp.com/IjuE40TzjIyFTEYdeJ3hrq",
  },
  {
    id: 9,
    name: "Battle Royale",
    describe:
      "Survival of the fittest: Only one can emerge victorious in this epic battle royale !",
    date: "21th and 22th March, 2024",
    time: "Update Soon",
    type: "event",
    venue: "Galeria 204 ",
    Days: 2,
    event_type: "Previous",
    check_type: true,
    logo: "https://i.ibb.co/yVWWWXG/free-fire-min.png",
    short_desc: "👉 Pitch & Win exciting prizes",
    form_url: "/events-1",
    wp_grp_url: "https://chat.whatsapp.com/CVkN8Vwzh2AKYFzLwx8S1m",
  },
];
