export const club_alumni_details = [
  {
    id: 8,
    firstname: 'Asish',
    fullname: 'Asish Panigrahi',
    description: `interest for web development and Cloud Computing.`,
    desgination: 'Web Developer',
    member_type: 'Operational Lead',
    member:"student",
    profile_img: 'https://avatars.githubusercontent.com/u/95563674?v=4',
    linkedln_link: `https://www.linkedin.com/in/asish-panigrahi-a43439175`,
    github_link: `https://github.com/Tugi01`,
    year: '2020'
  },
  {
    id: 12,
    firstname: 'Sambit',
    fullname: 'Sambit Sargam Ekalabya',
    description: ` Blockchain development, Web development, Cloud Computing. \n"Aspire to create an impact on human ergonomics using technology by creating meaningful solutions."\n`,
    desgination: 'Full Stack Blockchain Developer',
    member_type: 'Technical Lead',
    member:"student",
    profile_img: 'https://avatars.githubusercontent.com/u/70655824?v=4',
    linkedln_link: `https://www.linkedin.com/in/sambitsargam/`,
    github_link: `https://github.com/sambitsargam`,
    year: '2020'
  },
  {
    id: 9,
    firstname: 'Ashutosh',
    fullname: 'Ashutosh Tripathy',
    description: `intrested in coding & web development`,
    desgination: 'Full stack web developer',
    member_type: 'Secretary',
    member:"student",
    profile_img: 'https://avatars.githubusercontent.com/u/76964993?v=4',
    linkedln_link: `https://www.linkedin.com/in/ashutosh-tripathy-9b05aa203/`,
    github_link: `https://github.com/AshutoshTripathy21`,
    year: '2020'
  },
  {
    id: 8,
    firstname: 'Anikesh',
    fullname: 'Anikesh Aman',
    description: `interest for  Cloud Computing.`,
    desgination: '',
    member_type: 'Co-Marketing Lead',
    member:"student",
    profile_img: 'https://avatars.githubusercontent.com/u/96464888?v=4',
    linkedln_link: `https://www.linkedin.com/in/anikesh-aman-527404210`,
    github_link: `https://github.com/8512ani`,
    year: '2020'
  },
  {
    id: 8,
    firstname: 'Charan',
    fullname: 'V.Charanraju',
    description: `interest for android development and Cloud Computing.`,
    desgination: 'Android Developer',
    member_type: 'Co-Technical Lead',
    member:"student",
    profile_img: 'https://avatars.githubusercontent.com/u/96484862?v=4',
    linkedln_link: `https://www.linkedin.com/in/vysyaraju-raju-731151227/`,
    github_link: `https://github.com/VCharanRaju12`,
    year: '2020',
  },
  {
    id: 11,
    firstname: 'Vikash',
    fullname: 'Vikash Kumar',
    description: `interest in coding and web development.`,
    desgination: '',
    member_type: 'Co-Technical Lead',
    member:"student",
    profile_img: 'https://avatars.githubusercontent.com/u/72180033?v=https://avatars.githubusercontent.com/u/72180033?v=4',
    linkedln_link: `https://www.linkedin.com/in/vikash-kumar-b00b35212`,
    github_link: `https://github.com/vikashkumar8507`,
    year: '2020'
  },
  {
    id: 12,
    firstname: 'Ashutosh',
    fullname: 'Ashutosh Pattnaik',
    description: `Interesting in coding & web development`,
    desgination: '',
    member_type: 'Co-Academic Lead',
    member:"student",
    profile_img: 'https://avatars.githubusercontent.com/u/76533325?s=400&u=88c069156bcf3ab1cba5abc49ac492a28e53eba6&v=4.png ',
    linkedln_link: `https://www.linkedin.com/in/ashutosh-pattnaik-386415229/`,
    github_link: `https://github.com/Pattnaikbabu3`,
    year: '2020'
  },
   {
    id: 9,
    firstname: 'ANKIT',
    fullname: 'Ankit mishra',
    description: `intrested in coding & web development`,
    desgination: 'web developer',
    member_type: 'Co-Academic Lead',
    member:"student",
    profile_img: 'https://avatars.githubusercontent.com/u/96532131?v=4',
    linkedln_link: `https://www.linkedin.com/in/ankit-mishra-0a5467213/`,
    github_link: `https://github.com/1S2S-ANKIT`,
    year: '2020'
  },


    {
      id: 1,
      firstname: 'Debarshi',
      fullname: 'Debarshi Mondal',
      description: `Loved to build Serverless Scaleable Web
      applications on Cloud. Worked on how to Build Serverless Scaleable, MERN/full-stack
      web apps, and APIs.`,
      desgination: 'Full Stack Web & Cloud Native Developer',
      member_type: 'Representative',
      profile_img: 'https://avatars.githubusercontent.com/u/52108126?v=4',
      linkedln_link: `https://www.linkedin.com/in/debarshi-mondal-developer/`,
      github_link: `https://github.com/LENO-DEV`,
      year: '2018'
    },
    {
      id: 2,
      firstname: 'Sunil',
      fullname: 'Sutari Sunil reddy',
      description: `interest for Android App Development,Graphics Design and Cloud Computing.`,
      desgination: 'Android App Developer & Graphics Designer',
      member_type: 'Representative',
      profile_img: 'https://avatars.githubusercontent.com/u/66204882?v=4',
      linkedln_link: `https://www.linkedin.com/in/sutari-sunil-reddy-b80796196/`,
      github_link: `https://github.com/Sunilreddy0`,
      year: '2018'
    },
    {
      id: 3,
      firstname: 'Suman',
       fullname: 'Suman Patra',
      description: `Love and interest for web development and Cloud Computing. So, far I learned and hosted websites on cloud, as well as hands on experience on cloud.`,
      desgination: 'Web Developer',
      member_type: 'Core Member',
      profile_img: 'https://avatars.githubusercontent.com/u/64701665?v=4',
      linkedln_link: `https://www.linkedin.com/in/suman-patra-0598a11a7/`,
      github_link: `https://github.com/sumanpatra-123`,
      year: '2018'
    },
    {
      id: 4,
      firstname: 'Prasanth',
      fullname: 'Sriramadasu Prasanth Kumar',
      description: `Passionate towards data science, devops and cloud computing. Worked on many clouds like AWS, Azure, GCP, OpenStack and automation technologies like devOps.`,
      desgination: 'Machine Learning, DevOps & Cloud Computing',
      member_type: 'Core Member',
      profile_img: '',
      linkedln_link: `https://www.linkedin.com/in/sriramadasu-prasanth-kumar-917703184/`,
      github_link: `https://github.com/Sriramadasu`,
      year: '2018'
    },
    {
      id: 5,
      firstname: 'Nishan',
      fullname: 'Nishan kumar tripathy',
      description: `interest for software development and Cloud Computing.`,
      desgination: 'Software developer',
      member_type: 'Core Member',
      profile_img: 'https://avatars.githubusercontent.com/u/61120029?v=4',
      linkedln_link: `https://www.linkedin.com/in/nishan-tripathy-67a935195`,
      github_link: `https://github.com/nishankumartripathy`,
      year: '2018'
    },
    {
      id: 6,
      firstname: 'Samir',
      fullname: 'Samir Gouda',
      description: `Enthusiasts in Cloud Computing.Currently exploring on Machine Learning field.`,
      desgination: 'Machine Learning Learner',
      member_type: 'Core Member',
      profile_img: 'https://avatars.githubusercontent.com/u/64476238?v=4',
      linkedln_link: `https://www.linkedin.com/in/samir-gouda-a70b461a6`,
      github_link: `https://github.com/samir282`,
      year: '2018'
    },
    {
      id: 7,
      firstname: 'Subha',
      fullname: 'Subha Das',
      description: `interest in Machine Learning and AI.`,
      desgination: 'Software developer',
      member_type: 'Core Member',
      profile_img: 'https://avatars.githubusercontent.com/u/73764750?v=4',
      linkedln_link: `https://www.linkedin.com/in/subha-das-5a7807195/`,
      github_link: `https://github.com/su-d`,
      year: '2018'
    },
    
    {
      id: 8,
      firstname: 'Sai',
      fullname: 'P Sai Kumar',
      description: `interest in Development,Designing and Analytics`,
      desgination: 'Software developer',
      member_type: 'Core Member',
      profile_img: 'https://avatars.githubusercontent.com/u/89205186?v=4',
      linkedln_link: `https://www.linkedin.com/in/p-sai-kumar-270475156/`,
      github_link: `https://github.com/sai28210`,
      year: '2018'
    },
    {
      id: 9,
      firstname: 'Soumya',
      fullname: 'Soumyajeet Khuntia',
      description: `interest in coding leads to problem solver using different programming language and into the 
      cloud computing domain`,
      desgination: 'Software developer',
      member_type: 'Core Member',
      profile_img: 'https://avatars.githubusercontent.com/u/70887993?v=4',
      linkedln_link: `https://www.linkedin.com/in/soumyajeet-khuntia-a68855160/`,
      github_link: `https://github.com/Soumyajeet02`,
      year: '2018'
    },
    {
      id: 10,
      firstname: 'Sital',
      fullname: 'Sital Khadanga',
      description: `interest in Machine Learning and data analysis.`,
      desgination: 'Software developer',
      member_type: 'Core Member',
      profile_img: 'https://avatars.githubusercontent.com/u/85921948?v=4',
      linkedln_link: `https://www.linkedin.com/in/sital-khadanga-7562271b1/`,
      github_link: `https://github.com/sitalkhadanga`,
      year: '2018'
    },
    {
      id: 11,
      firstname: 'Ashish',
      fullname: 'Ashish Kumar',
      description: `interest in Cloud and Stock market`,
      desgination: 'Software developer',
      member_type: 'Core Member',
      profile_img: 'https://avatars.githubusercontent.com/u/46805770?v=4',
      linkedln_link: `https://www.linkedin.com/in/ashishisone/`,
      github_link: `https://github.com/ashishacker49`,
      year: '2018'
    },
    {
      id: 12,
      firstname: 'Priyanshu',
       fullname: 'Priyanshu Mishra',
      description: `Love and interest for web development and Cloud Computing. So, far I learned and hosted websites on cloud.`,
      desgination: 'Web Developer',
      member_type: 'Core Member',
      profile_img: 'https://avatars.githubusercontent.com/u/78350727?s=400&u=b4835ee364b742de9ff8d8ca3263bbd7dbd62782&v=4',
      linkedln_link: `https://www.linkedin.com/in/priyanshu-mishra-7654181a8`,
      github_link: `https://github.com/priyanshu-mishra-18`,
      year: '2018'
    },
    {
      id: 13,
      firstname: 'Ritik',
      fullname: 'Ritik Kumar',
      description: `interest for web development and Cloud Computing.`,
      desgination: 'Web Developer',
      member_type: 'Core Member',
      profile_img: 'https://avatars.githubusercontent.com/u/80706708?v=4',
      linkedln_link: `https://www.linkedin.com/in/ritik-kumar-3a1165225/`,
      github_link: `https://github.com/ritikkumar04`,
      year: '2018'
    },
    {
      id: 13,
      firstname: 'Pradipta',
      fullname: 'Pradipta Pentha Behera',
      description: `Enthusiast in AI and Computer Vision Research`,
      desgination: 'Data Analyst',
      member_type: 'Operational Lead',
      profile_img: 'https://avatars.githubusercontent.com/u/77044609?v=4',
      linkedln_link: `www.linkedin.com/in/pradiptabehera/`,
      github_link: `https://github.com/PradiptaBehera8558`,
      year: '2019'
    },
  
    {
      id: 14,
      firstname: 'Vikas',
       fullname: 'Vikas Kumar',
      description: `Interest for web development and Cloud Computing. So, far I learned to build and host websites and learning cloud computing.`,
      desgination: 'Web Developer',
      member_type: 'Technical  Lead',
      profile_img: 'https://avatars.githubusercontent.com/u/93202107?v=4',
      linkedln_link: `https://www.linkedin.com/in/vikas-kumar-3496501aa/`,
      github_link: `https://github.com/Vikas1211K`,
      year: '2019'
    },
    
    {
      id: 15,
      firstname:'Asutosh',
      fullname: 'Asutosh Das',
      description: `interest for web development and Cloud Computing and machine learing`,
      desgination: 'Frontend developer',
      member_type: 'Hiring Lead',
      profile_img: 'https://avatars.githubusercontent.com/u/87987600?v=4',
      linkedln_link: `https://www.linkedin.com/in/asutosh-das-082b5419a`,
      github_link: `https://github.com/asu2002`,
      year: '2019'
    },
   {
      id: 16,
      fullname: "Rajlaxmi Hota ",
      firstname: "Rajlaxmi",
      description: "A stretegic Human Resource learner; Good understanding in Operational Management. Salesforce Admin & Developer, \nEnthusiasts in Cloud Development and Cloud Analytics\n",
      desgination: "Cloud Analyst",
      member_type: "Public Relations Lead",
      github_link: "https://github.com/RajLaxmiHota",
      linkedln_link: "www.linkedin.com/in/rajlaxmihota",
      profile_img: "https://avatars.githubusercontent.com/u/117388332?v=4",
      type: "new",
      year: "2019"
   },
    {
      id: 17,
      firstname: 'Vinati',
      fullname: 'Perikala Vinati',
      description: `interest in Machine Learning and AI.`,
      desgination: 'Software developer',
      member_type: 'Secretary',
      profile_img: 'https://avatars.githubusercontent.com/u/75937844?v=4',
      linkedln_link: `https://www.linkedin.com/in/vinati-perikala-1630481b9/`,
      github_link: `https://github.com/Vinati098`,
      year: '2019'
    },
  
  
    {
      id: 18,
      firstname: 'Amit',
      fullname: 'B.AMIT KUMAR PATRO',
      description: `My area of interest is DBMS, Machine Learning, MySQL, CSS, HTML.`,
      desgination: 'Software developer',
      member_type: 'Academic Lead',
      profile_img: 'https://avatars.githubusercontent.com/u/77608956?v=4',
      linkedln_link: `https://www.linkedin.com/in/b-amit-kumar-patro-3835b71b2`,
      github_link: `https://github.com/Bamit-2021`,
      year: '2019'
    },
  
  
    {
      id: 19,
      firstname: 'AYUSH',
      fullname: 'AYUSH GUPTA',
      description: `interest for Android App Development and Cloud Computing.`,
      desgination: 'Android devloper',
      member_type: 'Core Member',
      member:"student",
      profile_img: 'https://avatars.githubusercontent.com/u/83214495?v=4',
      linkedln_link: `https://www.linkedin.com/in/ayush-gupta-557175158`,
      github_link: `https://github.com/ayushgupta536`,
      year: '2019'
    },
    
  ];
  